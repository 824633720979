<template>
  <b-overlay :variant="skin" :show="loading && is_accounts" :no-wrap="loading && is_accounts" spinner-variant="warning">
    <template v-slot:overlay>
      <p class="mb-2">Carregando suas configurações...</p>
      <b-overlay :show="loading && is_accounts" spinner-variant="primary" spinner-type="grow" opacity="0"></b-overlay>
    </template>
    <template>
      <button style="background-color: white; color: white;border:none;" ref="redirectButton"
        @click="redirectToPage">Redirecionar</button>
    </template>
  </b-overlay>
</template>

<script>
/* eslint-disable global-require */
import {
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import _authService from "@/services/auth-service";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      logo: require("@/assets/images/logo/logo.svg"),
      is_accounts: false,
      loading: false,
      required,
      email,
      google_token: null,
      showLoginForm: false,
      googleIcon: require("@/assets/images/svg/google.svg"),
    };
  },
  created() {
    if (this.$route.query.token) {
      this.is_accounts = true;
    }
    localStorage.clear();
  },
  mounted() {

    if (this.$route.query.token) {
      this.setConfigAccounts();
    }

    this.$nextTick(() => {
      this.$refs.redirectButton.click();
    });
  },
  methods: {
    redirectToPage() {
      this.loading = true;
      const returnUrl = encodeURIComponent(window.location.origin + '/auth-callback');
      const token = this.$route.query.token;
      const redirect = this.$route.query.redirect;
      let authUrl = `${process.env.VUE_APP_API}/api/auth?returnUrl=${returnUrl}`;
      if (token) {
        authUrl += `&token=${token}`;
      }
      if (redirect) {
        authUrl += `&redirect=${redirect}`;
      }
      window.location.href = authUrl;
    },
    setConfigAccounts() {
      this.$store.dispatch("verticalMenu/UpdateMenuCollapsed", false);
      localStorage.setItem("login-accounts", "true");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>